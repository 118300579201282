import React, { useEffect, useState } from "react"; 
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import CsvFileUpload from "../Components/CsvFileUpload";
import UserQueryList from "../Components/UserQueriesList";
import strings from "../localization";

const Home = () => {
  const authUser = useSelector((state) => state.AuthReducer.authUser);
  const navigate = useNavigate();
  const [userQueries, setUserQueries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const limit = 10;

  const fetchUserQueries = async (page = 0) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/user-queries?user_id=${authUser.id}&page=${page + 1}&limit=${limit}`);
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      if (response.status === 403) {
        navigate('/');
        return;
      }
      
      const data = await response.json();
      setUserQueries(data.queries);
      setPageCount(Math.ceil(data.totalCount / limit));

    } catch (error) {
      console.error('Failed to fetch user queries:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = async (queryId) => {
    const newName = prompt("Enter the new name for this query:");
    if (newName) {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/user-queries/${queryId}?user_id=${authUser.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: newName }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || "Failed to update query");
            }

            alert("Query name updated successfully!");
            fetchUserQueries(currentPage); // Refresh the list
        } catch (error) {
            console.error("Failed to update query name:", error.message);
            alert("Error updating query: " + error.message);
        }
    }
};

  const handleDelete = async (queryId) => {
    if (window.confirm("Are you sure you want to delete this query?")) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/user-queries/${queryId}?user_id=${authUser.id}`, {
          method: 'DELETE',
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || "Failed to delete query");
        }

        alert("Query deleted successfully!");
        fetchUserQueries(currentPage); 
      } catch (error) {
        console.error("Failed to delete query:", error.message);
        alert("Error deleting query: " + error.message);
      }
    }
  };

  useEffect(() => {
    if (!authUser?.id) {
      navigate('/login');
    } else {
      fetchUserQueries();
    }
  }, [authUser, navigate]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchUserQueries(pageNumber);
  };

  return (
    <div className="main-container">
      {authUser?.id && (
        <p>Please upload your application list in CSV format.</p>
      )}
      <CsvFileUpload />

      {loading ? (
        <p>Loading...</p>
      ) : (
        <UserQueryList
          userQueries={userQueries}
          currentPage={currentPage}
          pageCount={pageCount}
          onPageChange={handlePageChange}
          onEdit={handleEdit} 
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default Home;
