import React from 'react';
import { List, ListItem, ListItemText, IconButton, Typography, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import '../assets/scss/components/UserQueriesList.scss';

const UserQueryList = ({ userQueries, currentPage, pageCount, onPageChange, onEdit, onDelete }) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    if (pageCount <= 5) {
      for (let i = 0; i < pageCount; i++) {
        pageNumbers.push(
          <li key={i} className={currentPage === i ? "active" : ""}>
            <button onClick={() => onPageChange(i)}>{i + 1}</button>
          </li>
        );
      }
    } else {
      pageNumbers.push(
        <li key={0} className={currentPage === 0 ? "active" : ""}>
          <button onClick={() => onPageChange(0)}>1</button>
        </li>
      );

      if (currentPage > 2) {
        pageNumbers.push(<li key="ellipsis-start">...</li>);
      }

      for (let i = Math.max(1, currentPage - 1); i <= Math.min(pageCount - 2, currentPage + 1); i++) {
        pageNumbers.push(
          <li key={i} className={currentPage === i ? "active" : ""}>
            <button onClick={() => onPageChange(i)}>{i + 1}</button>
          </li>
        );
      }

      if (currentPage < pageCount - 3) {
        pageNumbers.push(<li key="ellipsis-end">...</li>);
      }

      pageNumbers.push(
        <li key={pageCount - 1} className={currentPage === pageCount - 1 ? "active" : ""}>
          <button onClick={() => onPageChange(pageCount - 1)}>{pageCount}</button>
        </li>
      );
    }
    return pageNumbers;
  };

  return (
    <div className="user-query-list">
      <Typography variant="h1" gutterBottom className="query-text">User Queries</Typography>
      <List>
        {userQueries.map((query) => (
          <React.Fragment key={query.query_id}>
            <ListItem>
              <Link to={`/score/${query.query_id}`} style={{ textDecoration: 'none', flexGrow: 1 }}>
                <ListItemText
                  primary={`${query.name}`}
                  className="clickable-text"
                />
              </Link>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(query.query_id);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(query.query_id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <ul className="pagination">
        <li className={currentPage === 0 ? "disabled" : ""}>
          <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 0}>Previous</button>
        </li>
        {renderPageNumbers()}
        <li className={currentPage === pageCount - 1 ? "disabled" : ""}>
          <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === pageCount - 1}>Next</button>
        </li>
      </ul>
    </div>
  );
};

export default UserQueryList;
